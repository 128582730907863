import api from './base'

export default {
  getAll ({ filters = {} }) {
    const filtersStr = Object.entries(filters).reduce((acc, [key, value]) => {
      acc += acc.length ? `&${key}=${encodeURIComponent(value)}` : `${key}=${encodeURIComponent(value)}`
      return acc
    }, '')

    return api().get(`tasks?${filtersStr}`)
  },
  getById ({ id }) {
    return api().get(`tasks/${id}`)
  },
  create ({ item }) {
    return api().post('tasks', item)
  },
  update ({ id, item }) {
    return api().put(`tasks/${id}`, item)
  },
  delete ({ id }) {
    return api().delete(`tasks/${id}`)
  },
}
